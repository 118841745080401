import * as React from "react"
import styled from "styled-components"

const GlowStick = ({ width = 80, height=4, className }) => (
  <Stick width={width} height={height} className={className}/>
)

export default GlowStick


const Stick = styled.div`
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  background: #FFFFFF;
  box-shadow: 0 1px 8px rgba(216, 179, 110, 0.6), 0 4px 30px #FF8B03, 0 0 20px #FF5C00, inset 0 0 1px #E87B19, inset 0 0 2px #FFFE91;
  filter: blur(1px);
  border-radius: 2px;
`
