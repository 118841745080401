class Vec2 {
  constructor(x, y) {
    this.x = x
    this.y = y
  }

  length() {
    return Math.sqrt(this.x * this.x + this.y * this.y)
  }

  normalized() {
    return {
      x: this.x / this.length(),
      y: this.y / this.length()
    }
  }

  dot (b) {
    const a = this.normalized()
    const bNorm = b.normalized()
    return a.x * bNorm.x + a.y * bNorm.y
  }

  getAngleToPoint(point) {
    let angle =  Math.PI + Math.atan2(this.x + point.x, this.y - point.y)
    return angle * 180 / Math.PI
  }
}

export default Vec2