import * as React from "react"
import styled from "styled-components"
import crossSvg from "../../images/cross3.svg"

const Cross = ({ width, hideMobile, hideDesktop, position, className}) => {
  return (
    <CrossElement
      src={crossSvg}
      alt=""
      width={width}
      className={className}
      position={position}
      hideMobile={hideMobile}
      hideDesktop={hideDesktop}
    />
  )
}

export default Cross


const CrossElement = styled.img`
  position: absolute;
  width: ${props => props.width + "px"};
  height: auto;
  opacity: 1;
  filter: brightness(0.95);
  ${props => props.position}
  ${props => props.hideMobile ? "display: none;" : ""}
  
  @media only screen and (min-width: 768px) {
    ${props => props.hideDesktop ? "display: none;" : "display: block;"}
  }
`
