import * as React from "react"
import styled from "styled-components"
import shadowImg from "../../images/wall_shadow2.png"
import Vec2 from "../../utils/vec2"

class Wall extends React.Component {
  constructor(props) {
    super(props);
    this.props = props
  }

  componentDidMount() {
    const lightRect = document.getElementById('light1').getBoundingClientRect()
    this.lightPos1 = new Vec2(-(lightRect.left), lightRect.top)

    const lightRect2 = document.getElementById('light2').getBoundingClientRect()
    this.lightPos2 = new Vec2(-(lightRect2.left), lightRect2.top)

    window.addEventListener('scroll', this.scrollHandler.bind(this))
    this.scrollHandler()
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler.bind(this))
  }

  scrollHandler() {
    const wallEl = document.getElementById(this.props.id)
    if(!wallEl) return
    const wallRect = wallEl.getBoundingClientRect()
    const wallRightPos = new Vec2(wallRect.right, wallRect.top + wallEl.offsetHeight/2)
    const angle1 = 90 - wallRightPos.getAngleToPoint(this.lightPos1)
    const angle2 = 90 - wallRightPos.getAngleToPoint(this.lightPos2)
    window.requestAnimationFrame(() => {
      wallEl.querySelector('.shadow-short').style.transform = "rotateZ(" +angle1+"deg) skewX("+angle1+"deg)"
      wallEl.querySelector('.shadow-long').style.transform = "rotateZ(" +angle2+"deg) skewX("+angle2+"deg)"
      wallEl.querySelector('.glow').style.transform = "rotateZ(" +angle2+"deg) skewX("+angle2+"deg)"
    })
  }

  render() {
    const {className, id, hideDesktop, hideMobile, height, position, longShadow} = this.props
    const cssHeight = height ? height + "px" : "100%"
    const shortOffset = longShadow ? 300 : 100
    const longOffset = longShadow ? 1000 : 300
    const imageOpacity = longShadow ? 0.8 : 0.6

    return(
      <WallContainer id={id} hideDesktop={hideDesktop} hideMobile={hideMobile} className={className} height={cssHeight} position={position}>
        <Shadow className="shadow-long" offset={longOffset}><ShadowImage offset={longOffset} opacity={imageOpacity} src={shadowImg} alt="" /></Shadow>
        <Shadow className="shadow-short" offset={shortOffset}><ShadowImage offset={shortOffset} opacity={imageOpacity} src={shadowImg} alt="" /></Shadow>
        <Glow className="glow" />
      </WallContainer>
    )
  }
}

export default Wall

const WallContainer = styled.div`
  width: 3px;
  position: absolute;
  height: ${props => props.height};
  display: ${props => props.hideMobile ? "none" : "block"};
  z-index: 1;
  border-radius: 1px;
  background-color: #ffffff;
  box-shadow: -3px 0 8px rgba(112, 123, 142, 0.4), -1px 0 2px #9CA8C0, inset 0.5px 0 0 #DDE3F9;
  ${props => props.position ? props.position : ""}
  
  @media only screen and (min-width: 768px) {
    display: ${props => props.hideDesktop ? "none" : "block"};
  }
`

const Shadow = styled.div`
  position: absolute;
  left: -${props => props.offset}px;
  z-index: 1;
  pointer-events: none;
  height: 100%;
  transform-origin: right;
  transform: rotateZ(0deg) skewX(0deg);
`


const ShadowImage = styled.img`
    position: relative;
    width: ${props => props.offset}px;
    transform: translate3D(1%, 0%, 0);
    height: 102%;
    float: right;
    opacity: ${props => props.opacity};
`

const Glow = styled.div`
  position: absolute;
  z-index: 1;
  opacity: 0.8;
  pointer-events: none;
  height: 100%;
  top: 2px;
  transform-origin: left;
  filter: blur(4px);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 12.5%, rgba(255, 255, 255, 0) 69.79%);
  transform: rotateZ(0deg) skewX(0deg);
  width: 200px;
  right: -200px;
`
