import * as React from "react"
import styled from "styled-components"
import RectShadowImg from '../../images/drop_shadow_rect.png'

const WideImage = ({ image, className, children }) => {
  const [hovered, setHovered] = React.useState(false)

  return (
    <Container
      onMouseEnter={() => {setHovered(true)}}
      onMouseLeave={() => {setHovered(false)}}
      className={className}
    >
      <div className="motion" data-y-from={100} data-y-to={-100} style={{
        position: "relative",
        zIndex: 3
      }}>
        <Shadow src={RectShadowImg} alt="" hovered={hovered}/>
        <Image src={image} alt="" hovered={hovered}/>
      </div>

      {children}
    </Container>
  )
}

export default WideImage


const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 290px;
  
  @media only screen and (min-width: 768px) {
    margin-top: 160px;
  }
  
  @media only screen and (min-width: 1024px) {
    margin-top: 40px;
  }
`

const Image = styled.img`
  position: relative;
  border-radius: 2px;
  width: 100%;
  height: 72vw;
  object-fit: cover;
  object-position: center;
  z-index: 3;
  transition: transform 0.5s ease-in-out;
  
  @media only screen and (min-width: 1024px) {
    height: auto;
    object-fit: unset;
    object-position: unset;
  }
`

const Shadow = styled.img`
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% + 60px);
  width: 100%;
  height: 100%;
  transform:translate3d(-50%, -50%, 0) scale(1.3);
  z-index: 1;
  pointer-events: none;
  transition: filter 0.5s ease-in-out;
`
