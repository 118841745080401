import * as React from "react"
import styled from "styled-components"
import materials from "../materials"

const MaterialContainer = ({ children, style, dataYFrom, dataYTo, minWidth, mt, mb, padding = 48, width, columns, borderRadius = 3, material, circle, zIndex = 4, className}) => {
  let cssWidth = width ? width + "px" : (columns ? (Math.round(100/columns) - 1) + "%" : "100%");
  return (
    <GlassContainer
      material={materials[material]}
      width={cssWidth}
      minWidth={minWidth ? minWidth + "px" : cssWidth}
      borderRadius={borderRadius}
      padding={circle ? 0 : padding}
      className={className}
      circle={circle}
      zIndex={zIndex}
      mt={mt}
      mb={mb}
      style={style}
      data-y-from={dataYFrom}
      data-y-to={dataYTo}
    >
      {circle && <CircleContent className="center-vertical circle-content" padding={padding}>{children}</CircleContent>}
      {!circle && (<>{children}</>)}
    </GlassContainer>
  )
}

export default MaterialContainer


const GlassContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  min-width: ${props => props.minWidth};
  z-index: ${props => props.zIndex};
  padding: ${props => props.padding/2 + "px"};
  flex: 0 0 auto;
  ${props => props.material ? props.material : ""}
  ${props => props.circle ? "border-radius: 50%;" : "border-radius: " + props.borderRadius + "px;"}
  ${props => props.circle ? "&::after {content: ''; display: block; margin-bottom: 100%;}" : ""}
  ${props => props.circle ? "overflow: hidden;" : ""}
  ${props => props.mt ? "margin-top: " +props.mt + "px;" : ""}
  ${props => props.mb ? "margin-bottom: " +props.mb + "px;" : ""}
  
  @media screen and (min-width: 1024px){
    padding: ${props => props.padding + "px"};
  }
`

const CircleContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: ${props => props.padding + "px"};
`
