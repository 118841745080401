import * as React from "react"
import styled from "styled-components"

const NarrowContent = ({ children, className, extraNarrow }) => {
  return (
    <Narrow className={className} extraNarrow={extraNarrow}>
      {children}
    </Narrow>
  )
}

export default NarrowContent


const Narrow = styled.div`
  position: relative;
  width: ${props => props.extraNarrow ? '660px' : '830px'};
  max-width: calc(100% - 16px);
  margin: 0 auto;
`
