import * as React from "react"
import styled from "styled-components"
import MaterialContainer from "../visualContainers/materialContainer"

const Disc = ({ width, position, hideMobile, hideDesktop, material, zIndex = 4, className}) => {
  return (
    <DiscElement
      material={material}
      width={width}
      className={className}
      circle={true}
      zIndex={zIndex}
      position={position}
      padding={0}
      hideMobile={hideMobile}
      hideDesktop={hideDesktop}
    />
  )
}

export default Disc


const DiscElement = styled(MaterialContainer)`
  position: absolute;
  ${props => props.position}
  ${props => props.hideMobile ? "display: none;" : ""}
  
  @media only screen and (min-width: 768px) {
    ${props => props.hideDesktop ? "display: none;" : "display: block;"}
  }
`
