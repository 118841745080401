const materials = {
  transparent: `
    background: rgba(15, 104, 185, 0.08);
    box-shadow: -20px 15px 30px rgba(16, 79, 114, 0.06), inset -0.5px 0.5px 0.5px rgba(255, 255, 255, 0.5), inset 1px 0px 1px rgba(112, 116, 139, 0.04);
    backdrop-filter: blur(8px);
  `,
  almostBlack: `
    background: #0c1524;
    box-shadow: -1px 1px 6px #45567E, -4px 4px 24px #546B95;
  `,
  lightBlue: `
    background: rgba(43, 110, 173, 0.16);
    box-shadow: -2px 2px 10px rgba(16, 79, 114, 0.06), inset 0.5px 0px 0.25px rgba(255, 255, 255, 0.55), inset 0.5px 0px 1px rgba(255, 255, 255, 0.3), inset -1px 0px 1px rgba(112, 116, 139, 0.04);
    backdrop-filter: blur(2px);
  `,
  lightBlueOpaque: `
    background: #E5EBFB;
    box-shadow: -20px 5px 25px rgba(16, 49, 114, 0.08), inset -1px -0.5px 0px rgba(255, 255, 255, 0.9), inset -2px -1px 1.5px rgba(255, 255, 255, 0.5), inset 1px 0px 1px rgba(112, 116, 139, 0.04), -20px 15px 30px rgba(16, 79, 114, 0.06);
  `,
  middleBlue: `
    background: rgba(11, 87, 130, 0.22);
    box-shadow: -20px 20px 40px rgba(16, 79, 114, 0.1);
    backdrop-filter: blur(8px);
  `,
  brightBlue: `
    background: #B0ECFB;
    box-shadow: -20px 5px 50px rgba(16, 79, 114, 0.5);
  `,
  brightBlueBorder: `
    border: 2px solid #B0ECFB;
  `,
  walled: `
    border: 1px solid #FFFFFF;
    box-shadow: -5px 5px 4px rgba(156, 168, 192, 0.4), -25px 10px 18px rgba(150, 163, 195, 0.25), inset -5px 5px 4px rgba(156, 168, 192, 0.4), inset -25px 10px 18px rgba(150, 163, 195, 0.25);
  `,
  lighterBlue: `
    background: rgba(91, 133, 171, 0.22);
    box-shadow: -20px 15px 30px rgba(16, 79, 114, 0.06), inset 0.5px -0.5px 0.5px rgba(255, 255, 255, 0.5), inset 1px 3px 0.5px rgba(145, 183, 235, 0.4);
    backdrop-filter: blur(6px);
  `,
  redGradient: `
    background: linear-gradient(225.54deg, rgba(226, 61, 71, 0.85) 15.01%, rgba(255, 111, 91, 0.85) 86.19%);
    box-shadow: -20px 15px 40px rgba(12, 21, 36, 0.3), inset -1px -1px 1px rgba(255, 255, 255, 0.6), inset 1px 0px 1px rgba(112, 116, 139, 0.04);
    backdrop-filter: blur(4px);
  `,
  yellowGradient: `
    background: linear-gradient(225.54deg, rgba(227, 166, 9, 0.85) 15.01%, rgba(252, 212, 69, 0.85) 86.19%);
    box-shadow: -20px 15px 40px rgba(12, 21, 36, 0.3), inset -3px -3px 2px rgba(255, 255, 255, 0.4), inset 1px 0px 1px rgba(112, 116, 139, 0.04);
    backdrop-filter: blur(4px);
  `,
  greenGradient: `
    background: linear-gradient(225.54deg, rgba(210, 255, 152, 0.75) 15.01%, rgba(118, 255, 156, 0.75) 86.19%);
    box-shadow: -20px 15px 40px rgba(12, 21, 36, 0.3), inset -3px -3px 2px rgba(255, 255, 255, 0.4), inset 1px 0px 1px rgba(112, 116, 139, 0.04);
    backdrop-filter: blur(4px);
  `,
  purpleGradient: `
    background: linear-gradient(225.54deg, rgba(92, 36, 250, 0.85) 15.01%, rgba(106, 68, 217, 0.85) 86.19%);
    box-shadow: -20px 15px 40px rgba(12, 21, 36, 0.3), inset -3px -3px 2px rgba(255, 255, 255, 0.4), inset 1px 0px 1px rgba(112, 116, 139, 0.04);
    backdrop-filter: blur(4px);
  `,
  peachGradient: `
    background: linear-gradient(225.54deg, rgba(255, 95, 45, 0.85) 15.01%, rgba(255, 112, 155, 0.85) 86.19%);
    box-shadow: -20px 15px 40px rgba(12, 21, 36, 0.3), inset -3px -3px 2px rgba(255, 255, 255, 0.4), inset 1px 0px 1px rgba(112, 116, 139, 0.04);
    backdrop-filter: blur(4px);
  `,
  blueGradient: `
    background: linear-gradient(225.54deg, rgba(83, 224, 255, 0.75) 15.01%, rgba(118, 255, 206, 0.75) 86.19%);
    box-shadow: -20px 15px 40px rgba(12, 21, 36, 0.3), inset -3px -3px 2px rgba(255, 255, 255, 0.4), inset 1px 0px 1px rgba(112, 116, 139, 0.04);
    backdrop-filter: blur(4px);
  `,
}

export default materials

