import * as React from "react"
import styled from "styled-components"
import Wall from "../visualComponents/wall"

const PageTitle = ({ title, mobileWall, big, className, children }) => (
  <Container className={className} mobileWall={mobileWall} big={big}>
    <h1>{title}</h1>
    <RelativeWall id="title-wall" mobileWall={mobileWall} longShadow={true}/>
    {children}
  </Container>
)

export default PageTitle

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 40px;

  h1 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    color: #FFFFFF;
    z-index: 5;
    position: relative;
    margin: 0;
    display: inline-block;
    flex: 0 0 auto;
    max-width: ${props => props.mobileWall ? "70%" : "95%"};
    
    @media screen and (min-width: 768px){
      max-width: 95%;
    }
    
    
    @media screen and (min-width: 1024px){
      font-size: ${props => props.big ? "60" : "48"}px;
    }
  }
`

const RelativeWall = styled(Wall)`
  position: relative;
  flex: 0 0 auto;
  height: unset;
  margin-left: 40px;
  
  @media screen and (max-width: 767px){
    ${props => props.mobileWall ? "" : "display: none;"}
  }
`
